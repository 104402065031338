import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link } from "react-router-dom";
import { useDropzone } from 'react-dropzone';

function Dashboard() {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [activeTab, setActiveTab] = useState("files");
  const [files, setFiles] = useState([]);
  const [links, setLinks] = useState([""]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token, navigate]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: '*/*',
    maxSize: 100 * 1024 * 1024, // 100MB max
    onDrop: (acceptedFiles) => {
      const validFiles = acceptedFiles.filter(file => file.size <= 100 * 1024 * 1024);
      if (validFiles.length + files.length > 5) {
        alert("You can only upload up to 5 files.");
        return;
      }
      setFiles([...files, ...validFiles]);
    }
  });

  const addLinkField = () => {
    if (links.length >= 5) {
      alert("You can only add up to 5 links.");
      return;
    }
    setLinks([...links, ""]);
  };

  const removeLinkField = (index) => {
    setLinks(links.filter((_, idx) => idx !== index));
  };

  const handleLinkChange = (index, value) => {
    const updatedLinks = [...links];
    updatedLinks[index] = value;
    setLinks(updatedLinks);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (activeTab === "files") {
        if (files.length === 0) {
          alert("Please select at least one file.");
          setLoading(false);
          return;
        }
        const formData = new FormData();
        files.forEach(file => formData.append('files', file));
        await axios.post(`${process.env.REACT_APP_API_URL}/files/upload`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        });
        alert("Files uploaded successfully!");
        setFiles([]);
      } else {
        const validLinks = links.filter(link => link.trim() !== "");
        if (validLinks.length === 0) {
          alert("Please provide at least one link.");
          setLoading(false);
          return;
        }
        await axios.post(`${process.env.REACT_APP_API_URL}/files/upload-links`, { links: validLinks }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        alert("Links uploaded successfully!");
        setLinks([""]);
      }
    } catch (error) {
      console.error(error);
      alert("Upload failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="dashboard-container">
      <nav>
        <Link to="/reports">Reports</Link> |
        <button onClick={handleLogout} style={{ background: 'none', border: 'none', color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}>Logout</button>
      </nav>

      <h2>Dashboard</h2>

      <div className="tabs">
        <button
          className={`tab-button ${activeTab === "files" ? "active" : ""}`}
          onClick={() => setActiveTab("files")}
        >
          Files
        </button>
        <button
          className={`tab-button ${activeTab === "links" ? "active" : ""}`}
          onClick={() => setActiveTab("links")}
        >
          Links
        </button>
      </div>

      {activeTab === "files" && (
        <div className="file-upload-section">
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <p>Drag 'n' drop up to 5 files here, or click to select</p>
            <p>(Max 100MB per file)</p>
          </div>
          {files.length > 0 && (
            <ul className="file-list">
              {files.map((file, idx) => (
                <li key={idx}>{file.name} - {(file.size / (1024 * 1024)).toFixed(2)} MB</li>
              ))}
            </ul>
          )}
        </div>
      )}

      {activeTab === "links" && (
        <div className="link-upload-section">
          {links.map((link, idx) => (
            <div className="link-input" key={idx}>
              <input
                type="text"
                value={link}
                onChange={(e) => handleLinkChange(idx, e.target.value)}
                placeholder="https://example.com"
              />
              {idx > 0 && <button onClick={() => removeLinkField(idx)}>-</button>}
            </div>
          ))}
          {links.length < 5 && <button onClick={addLinkField}>+ Add another link</button>}
        </div>
      )}

      <button
        className="submit-button"
        onClick={handleSubmit}
        disabled={loading}
      >
        {loading ? "Uploading..." : "Submit"}
      </button>
    </div>
  );
}

export default Dashboard;
