import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Reports() {
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");

  // Debug: Check Token
  console.log("Token:", token);

  useEffect(() => {
    if (!token) {
      console.log("No token found, redirecting to login...");
      navigate("/login");
    } else {
      console.log("Token found, fetching reports...");
      fetchFiles();
    }
  }, [token, navigate]);

  const fetchFiles = async () => {
    try {
      console.log("Starting API request to fetch files...");
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/files/reports`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      console.log("API Response Data:", data);

      if (data && data.files) {
        setFiles(data.files);
        console.log("Files state updated:", data.files);
      } else {
        console.warn("Unexpected API response format:", data);
      }
    } catch (error) {
      console.error("Error fetching reports:", error);
      alert("Failed to fetch reports. Please try again.");
    } finally {
      console.log("API request completed. Stopping loading...");
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>User Reports</h2>
      {loading ? (
        <p>Loading...</p>
      ) : files.length === 0 ? (
        <p>No files found.</p>
      ) : (
        <table border="1" cellPadding="8" cellSpacing="0" style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th>Filename</th>
              <th>Report</th>
              <th>Size (KB)</th>
              <th>Link</th>
              <th>Status</th>
              <th>Uploaded At</th>
            </tr>
          </thead>
          <tbody>
            {files.map((file) => {
              console.log("Rendering File:", file); // Debug: File Data
              return (
                <tr key={file.id}>
                  <td>{file.filename}</td>
                  <td>
                    {file.reports_link ? (
                      <a href={file.reports_link} target="_blank" rel="noopener noreferrer">
                        View Report
                      </a>
                    ) : (
                      "No Report"
                    )}
                  </td>
                  <td>{(file.size / 1024).toFixed(2)}</td>
                  <td>{file.is_link || "False"}</td>
                  <td>{file.status}</td>
                  <td>{new Date(file.uploaded_at).toLocaleString()}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default Reports;
